import React, { Component } from 'react';
import axios from 'axios';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Link } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { FilterMatchMode } from 'primereact/api';
import { getUrl, formatDate, getJobTimeBadge } from '../planner/planner';
import { Button } from 'primereact/button';
import { decode } from 'html-entities';
import { Tooltip } from 'primereact/tooltip';
import { Toast } from 'primereact/toast';

export class Products extends Component { 
	constructor(props) {
		super(props);

		this.state = {
			products: [],
			productCount: null,
			isLoaded: false,
			expandedRows: null,
			filters: {
				global: { value: null, matchMode: FilterMatchMode.CONTAINS },
			},
			globalFilterValue: '',
			shouldUpdate: false,
			columns: [
				{ field: 'name', header: 'Title', sortable: true },
				{ field: 'formattedPrice', header: 'Sales Price', sortable: true },
				{ field: 'formattedCostPrice', header: 'Cost Price', sortable: true },
				{ field: 'stock', header: 'Free Stock', sortable: true },
				{ field: 'status', header: 'Type', sortable: true },
				{ field: 'manufacturer', header: 'Manufacturer', sortable: true },
				{ field: 'time', header: 'Target Time', sortable: false }
			],
			visibleColumns: null,
			lastSynced: null,
			errorToast: React.createRef()
		}

		this.editProductButton = this.editProductButton.bind(this);
	}

	/**
	 * When the component is loaded get the data from the REST API and set the columns based on the current users preferences
	 */
	componentDidMount() {
		document.title = this.props.pageTitle + ' - Planner';

		this.updateProducts();
		// If the user has custom columns set load them if not set every column as visible
		if(localStorage.getItem('visibleColumnsProducts')) {
			this.setState({
				visibleColumns: JSON.parse(localStorage.getItem('visibleColumnsProducts'))
			});
		} else {
			this.setState({
				visibleColumns: this.state.columns
			});
		}
	}

	/**
	 * Checks if the component should update based on if an product category has changed or the props for the component to reload
	 * 
	 * @param {Object} prevProps The previous state of the props
	 * @param {Object} prevState The previous state of the state
	 */
	componentDidUpdate(prevProps, prevState) {
		if(this.props.pageTitle !== prevProps.pageTitle || this.props.category !== prevProps.category || this.state.shouldUpdate !== prevState.shouldUpdate) {
			this.updateProducts(); 
			this.setState({shouldUpdate: false});
			document.title = this.props.pageTitle + ' - Planner';	
		}
	}

	/**
	 * Gets the products for the DataTable
	 */
	updateProducts() {
		var url = getUrl() + '/wp-json/planner/v1/products';

		// If loading a specific category append the query string to the REST request
		if(this.props.category) {
			url += '?category=' + this.props.category;
		}

		this.setState({
			isLoaded: false,
			products: []
		})

		axios.get(url, { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
		.then(res => {
			this.setState({
				products: res.data.products,
				lastSynced: res.data.last_synced,
				isLoaded: true
			});
		
			if(this.dt != undefined) {
				this.dt.reset();
			}
		}).catch(err => {
			this.state.errorToast.current.show({ severity: 'error', summary: 'Oops 🤦‍♂️', sticky: true, detail: 'An error has occurred, please contact IT support.' });
			console.log(err)
		});
	}

	/**
	 * Clears the global and status filters
	 */
	clearFilters() {
		let _filters = { ...this.state.filters };

		_filters['global'].value = null;

		this.setState({
			globalFilterValue: '',
			filters: _filters
		});
	}

	/**
	 * Create an array of all of the products
	 * 
	 * @returns {array} An array containing product information for the Datatable
	 */
	getProducts() {
    const tableRows = [];

		this.state.products.map(product =>
			tableRows.push({
				product_id: product.id,
				name: product.description,
				sku: product.sku,
				formattedPriceDisplay: decode(product.sales_price_display),
				formattedCostPriceDisplay: decode(product.average_cost_price_display),
				formattedPrice: product.sales_price,
				formattedCostPrice: product.average_cost_price,
				stock: product.stock,
				stockStatus: product.stock_status,
				categories: product.type,
				isLocked: product.locked,
				processes: product.processes,
				manufacturer: product.manufacturer,
				time: product.target_time,
			})
		);

		return tableRows;
	}

	/**
	 * Create a button which links to the individual product page
	 * 
	 * @param {Object} rowData The data associated to the row
	 * @returns {html} A button linking to view the product
	 */
	editProductButton(rowData) {
		const url = "/product/" + rowData['product_id'];

		let button = <div className="d-block text-end"><Link to={url} className="btn btn-primary"><i className="pi pi-lock me-2" style={{ fontSize: '0.8rem' }}></i>View</Link></div>;

		if(rowData.isLocked === false || rowData.isLocked.ID === this.props.currentLoggedInUser.id) {
			button = <div className="d-block text-end"><Link to={url} className="btn btn-primary"><i className="pi pi-pencil me-2" style={{ fontSize: '0.8rem' }}></i>Edit</Link></div>;
		}

		return button;
  }

	/**
	 * Clear the global filter
	 */
	clearSearch() {
		let _filters = { ...this.state.filters };

		_filters['global'].value = null;

		this.setState({
			globalFilterValue: '',
			filters: _filters
		})
	}

	/**
	 * Update the global filtering to filter table contents
	 * 
	 * @param {object} event Information for the current input
	 */
	onGlobalFilterChange(event) {
		const value = event.target.value;

		let _filters = { ...this.state.filters };

		_filters['global'].value = value;

		this.setState({filters: _filters, globalFilterValue: value })
	}

	/**
	 * When a user toggles a column set the state of the component and update the local storage so the users PC remembers which collumns to show
	 * 
	 * @param {Event} event The collumn which is being toggled
	 */
	onColumnToggle(event) {
		let selectedColumns = event.value;
		let orderedSelectedColumns = this.state.columns.filter((col) => selectedColumns.some((sCol) => sCol.field === col.field));

		this.setState({visibleColumns: orderedSelectedColumns})
		localStorage.setItem('visibleColumnsProducts', JSON.stringify(orderedSelectedColumns));
	}

	/**
	 * Create the template for the time column on the table. If the product is not a battery pack, show as N/A
	 *
	 * @param {Object} rowData The data associated to the row
	 * @returns {html} The time for the product in the badge format
	 */
	getTime(rowData) {
		if(rowData.time) {
			if(rowData.time !== 'N/A') {
				return getJobTimeBadge(rowData.time);
			} else {
				return 'N/A';
			}
		} else {
			return <span className="badge bg-danger text-white">Missing Time</span>
		}
	}

	/**
	 * Get the string decoded for the product title
	 * 
	 * @param {object} rowData 
	 * @returns {string} The decoded string for the name of the product
	 */
	getName(rowData) {
		return decode(rowData.name);
	}

	/**
	 * Create the template for the price column on the table
	 * 
	 * @param {Object} rowData The data associated to the row
	 * @returns {html} The price of the product
	 */
	getPrice(rowData) {
		if(rowData.formattedPriceDisplay) {
			return <span>{rowData.formattedPriceDisplay}</span>;
		}
	}

	/**
	 * Create the template for the price column on the table
	 * 
	 * @param {Object} rowData The data associated to the row
	 * @returns {html} The price of the product
	 */
	getCostPrice(rowData) {
		if(rowData.formattedCostPriceDisplay) {
			return <span>{rowData.formattedCostPriceDisplay}</span>;
		}
	}

	/**
	 * Create the template for the categories column on the table
	 * 
	 * @param {Object} rowData The data associated to the row
	 * @returns {html} The categories of the product
	 */
	getCategory(rowData) {
		if(rowData.categories != 'Uncategorised') {
			return <div className="badge bg-primary">{rowData.categories}</div>;
		} else {
			return <div className="badge bg-danger">{rowData.categories}</div>;
		}
	}

	/**
	 * Get the stock of the product
	 * 
	 * @param {Object} rowData The data associated to the row
	 * @returns {html || string} Get the html for the stock output  
	 */
	getStock(rowData) {
		let html = rowData.stock;

		if(rowData.stock === null && rowData.stockStatus === 'instock') {
			html = <span className="badge bg-success text-white">{'In Stock'}</span>;
		} else if (rowData.stock === null && rowData.stockStatus === 'outofstock') {
			html = <span className="badge bg-danger text-white">{'Out of Stock'}</span>;
		}

		return html;
	}

	/**
	 * Create the template for the bill of materials product edit button on the table
	 * 
	 * @param {Object} rowData The data associated to the row
	 * @returns {html} The Bill of Materials table button to enable users to view the products on the bill of materials easily 
	 */
	getBomLink(rowData) {
		return <div className="text-end"><Link to={"/product/" + rowData.id} className="btn btn-primary"><i className="pi pi-pencil" style={{ fontSize: '0.8rem' }}></i></Link></div>
	}

	/**
	 * Generate the template for the functionality when a user clicks to expand a row.
	 * 
	 * @param {Object} rowData The data associated to the row
	 * @returns The template for the row epansion which includes all of the notes for the order
	 */
	rowExpansionTemplate(rowData) {
		return (
			<div className="mx-5 mb-3">
				{
					rowData.processes != null && (
						<>
							<p className="h6 mt-3">Processes</p>
							{
								rowData.processes.map((process) => {
									return (<span className="badge bg-primary me-2">{process.activity.name}</span>)
								})
							}
						</>
					)
				}
			</div>
		);
	}

	/**
	 * Create the header for the DataTable with text filtering and toggling columns.
	 * 
	 * @returns {html} The DataTable header
	 */
	renderHeader() {
		return (
			<div className="container">
				<div className="d-flex justify-content-end">
						<div className="products-header">
							<MultiSelect placeholder="Select Product Columns" value={this.state.visibleColumns} options={this.state.columns} onChange={this.onColumnToggle.bind(this)} optionLabel="header" className="me-2 columnToggling" display="chip" />
							<span className="p-input-icon-left p-input-icon-right">
								<i className="pi pi-search" />
								<InputText placeholder="Search..." value={this.state.globalFilterValue} onChange={this.onGlobalFilterChange.bind(this)} />
								{
									this.state.globalFilterValue !== null && this.state.globalFilterValue !== '' && (
										<i className="pi pi-times" onClick={this.clearSearch.bind(this)} />
									)
								}
							</span>
						</div>
				</div>
			</div>
		);
	}

	/**
	 * Get the cost price header with the tooltip
	 * 
	 * @returns {html} The header for the cost price with the tooltip
	 */
	getCostPriceHeader() {
		return <><Tooltip target=".pi-question-circle" /><span>Cost Price <i className="pi pi-question-circle" style={{ fontSize: '0.75rem' }} 
			data-pr-tooltip="Cost price is currently averaged and excludes labour"
			data-pr-position="right"
			data-pr-at="right+5 top"
			data-pr-my="left center-2"
		></i></span></>;
	}

	render() {
		const { errorToast, isLoaded, visibleColumns, filters, expandedRows } = this.state;

		let title = this.props.title;
		let synced = <span><strong>Last Synced:</strong>&nbsp;{formatDate(this.state.lastSynced, true)}</span>

		if(this.props.capabilities.edit_products !== true) {
			title = 'Access Denied';
			synced = '';
		}

		let productCount = this.getProducts().length;

		if(this.state.productCount !== null) {
			productCount = this.state.productCount;
		}

		let loadingClass = 'isLoading';

		if(isLoaded) {
			loadingClass = 'notLoading';
		}

		return (
			<>
				<Toast ref={errorToast} position="center" />
				<div className="edit-order-bar position-sticky top-0 py-3 mb-5">
					<div className="mx-5 px-0 d-flex justify-content-between align-items-center w-100" style={{maxWidth: '100%'}}>
						<div className="d-flex align-items-center">
							<div>
								<Button className="bg-primary p-2 me-3 d-flex align-items-center rounded d-xxl-none" onClick={e => {this.props.updateSidebarOpened(true);}}>
									<i className="pi pi-bars text-white" style={{ fontSize: '1.25rem' }}></i>
								</Button>
							</div>
							<div>
								<h1 className="mb-0 h3">{title}</h1>
								<strong>Product Count: </strong>{productCount} of {this.getProducts().length}
							</div>
						</div>
						<div>{synced}</div>
					</div>
				</div>
				{
					this.props.capabilities.edit_products === true && visibleColumns !== null && ( // https://stackoverflow.com/questions/70682832/
					<div className="card mx-5">
						{
							!isLoaded && (
								<div className={"mx-5 mb-3"} style={{ position: 'absolute', top: '3rem', zIndex: '99999', left: '45%' }}>
									<i className="pi pi-spin pi-spinner" style={{ fontSize: '6rem' }}></i>
								</div>
							)
						}
						<DataTable className={loadingClass} ref={(el) => this.dt = el} onValueChange={filteredData => this.setState({productCount: filteredData.length})} expandedRows={expandedRows} onRowToggle={(e) => this.setState({expandedRows: e.data})} rowExpansionTemplate={this.rowExpansionTemplate} globalFilterFields={['sku', 'status', 'name']}  filters={filters} dataKey="sku" size="small" value={this.getProducts()} tableStyle={{ minWidth: '50rem' }} paginator rows={10} rowsPerPageOptions={[10, 25, 50]} sortOrder={1} sortField="sku" header={this.renderHeader(this)}>
							<Column key="sku" field="sku" header="Product Code" sortable={true} />

							{visibleColumns.map((col) => {

								// Absolute shit code but LocalStorage won't store the body functions. See if there's a better way to do this.
								if(col.field === 'time') {
									if(!this.props.hideTime && this.props.capabilities.edit_travellers) {
										return (<Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} body={this.getTime} />)
									} else {
										return '';
									}
								} else if (col.field === 'name') {
									return (<Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} body={this.getName} />)
								} else if (col.field === 'formattedPrice') {
									return (<Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} body={this.getPrice} alignHeader={'right'} bodyStyle={{ textAlign: 'right' }} />)
								} else if (col.field === 'formattedCostPrice') {
									return (<Column key={col.field} field={col.field} header={this.getCostPriceHeader} sortable={col.sortable} body={this.getCostPrice} alignHeader={'right'} bodyStyle={{ textAlign: 'right' }} />)
								} else if (col.field === 'stock') {
									return (<Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} body={this.getStock} alignHeader={'right'} bodyStyle={{ textAlign: 'right' }} />)
								} else if (col.field === 'status') {
									if(!this.props.singleCategory) {
										return (<Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} body={this.getCategory} />)
									} else {
										return '';
									}
								} else {
									return (<Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} />)
								}

							})}
							<Column body={this.editProductButton} header="" style={{ minWidth: '6rem' }} />
						</DataTable>
					</div>
					)
				}
			</>
		);
	}
}
export default Products