import React, { useState, useEffect } from 'react';
import { Menu } from 'primereact/menu';
import { ScrollPanel } from 'primereact/scrollpanel';
import { useHistory } from "react-router-dom";
import { Badge } from 'primereact/badge';
import { useLocation } from 'react-router';
import axios from 'axios';
import { getUrl } from '../planner/planner';

export default function Navigation(props) {
	var navigate = useHistory();
	const [isLoaded, setIsLoaded] = useState(false);
	const [navigation, setNavigation] = useState([]);
	const [statusCount, setStatusCount] = useState({});
	const location = useLocation();
	const [refresh, setRefresh] = useState(false);

	async function getStatuses() {
		const [statusCount] = await Promise.all([
			axios.get(getUrl() + '/wp-json/planner/v1/settings', { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} }).then().catch(err => console.log(err))
		]);

		setStatusCount(statusCount.data);
	}

	/**
	 * Check if the current location matches the path specified to enable active states
	 * 
	 * @param {String} path The path which will be used to check 
	 * @returns {String} Return the active class if the path matches the current location
	 */
	function isActive(path, secondPath) {	
		if(navigate.location.pathname === path || navigate.location.pathname.includes(secondPath)) {
			return 'p-menuitem-active';
		}
	}

	useEffect(() => {

		getStatuses();

		var inProductionCount = null;
		var shortageCount = null;
		var onHoldCount = null;

		//travellerStatusesCount
		var TravellerPreparedCount = null;
		var TravellerInProgressCount = null;
		var TravellerOnHoldCount = null;

		if(props.statuses != null) {
			//console.log(props.statuses);
			setIsLoaded(true);
			for (const [key, value] of Object.entries(props.statuses.order_statuses_count)) {

				if(key === 'processing') {
					inProductionCount = value;
				}
		 
				if(key === 'shortage') {
					shortageCount = value;
				}
	
				if(key === 'on-hold') {
					onHoldCount = value;
				}
			}
		}
	
		props.travellerStatusesCount.forEach(status => {
			if(status.name === 'In Production') {
				TravellerPreparedCount = status.total;
			}
	 
			if(status.name === 'Shortage') {
				shortageCount = status.total;
			}

			if(status.name === 'On Hold') {
				onHoldCount = status.total;
			}
		});

		let jobs = {};

		if(props.capabilities.edit_products) {
			jobs = {
				label: 'Jobs',
				items: [
					{
						label: 'Sales Orders',
						icon: 'pi pi-shopping-cart',
						command:  () => {
							navigate.push('/jobs/sales-order')
							props.onSidebarClose(true)
							props.onSidebarRefresh(true)
							setRefresh(true);
						},
						className: isActive('/jobs/sales-order', '/job/', false)
					},
					{
						label: 'Shortage',
						icon: 'pi pi-exclamation-triangle',
						template: (item, options) => {
							return (
								<>
									<div className={options.className} target={item.target} onClick={options.onClick}>
										<span className={'pi pi-exclamation-triangle me-2'}></span>
										<span className={options.labelClassName}>{item.label}</span> 
										{
											isLoaded && shortageCount > 0 && (
												<Badge className="ms-2" value={shortageCount} /> 
											)
										}
									</div>
								</>
							)
						},
						command: () => {
							navigate.push('/jobs/shortage')
							props.onSidebarClose(true)
							props.onSidebarRefresh(true)
							setRefresh(true);
						},
						className: isActive('/jobs/shortage')
					},
					{
						label: 'On Hold',
						icon: 'pi pi-ban',
						template: (item, options) => {
							return (
								<>
									<div className={options.className} target={item.target} onClick={options.onClick}>
										<span className={'pi pi-ban me-2'}></span>
										<span className={options.labelClassName}>{item.label}</span>
										{
											isLoaded && onHoldCount > 0 && (
												<Badge className="ms-2" value={onHoldCount} />
											)
										}
									</div>
								</>
							)
						},
						command:  () => {
							navigate.push('/jobs/on-hold')
							props.onSidebarClose(true)
							props.onSidebarRefresh(true)
						},
						className: isActive('/jobs/on-hold')
					},
					{
						label: 'Priority',
						icon: 'pi pi-exclamation-circle',
						template: (item, options) => {
							return (
								<>
									<div className={options.className} target={item.target} onClick={options.onClick}>
										<span className={'pi pi-exclamation-circle me-2'}></span>
										<span className={options.labelClassName}>{item.label}</span>
										{
											isLoaded && props.priority > 0 && (
												<Badge className="ms-2" value={props.priority} />
											)
										}
									</div>
								</>
							)
						},
						command: () => {
							navigate.push('/jobs/priority')
							props.onSidebarClose(true)
							props.onSidebarRefresh(true)
							setRefresh(true);
						},
				
						className: isActive('/jobs/priority')
					},
					{
						label: 'In Production',
						icon: 'pi pi-wrench',
						template: (item, options) => {
							return (
								<>
									<div className={options.className} target={item.target} onClick={options.onClick}>
										<span className={'pi pi-wrench me-2'}></span>
										<span className={options.labelClassName}>{item.label}</span>
										{
											isLoaded && inProductionCount > 0 && (
												<Badge className="ms-2" value={inProductionCount} />
											)
										}
									</div>
								</>
							)
						},
						command:  () => {
							navigate.push('/jobs/in-production')
							props.onSidebarClose(true)
							props.onSidebarRefresh(true)
							setRefresh(true);
						},
						className: isActive('/jobs/in-production')
					},
					{
						label: 'Completed',
						icon: 'pi pi-truck',
						command:  () => {
							navigate.push('/jobs/completed')
							props.onSidebarClose(true)
							props.onSidebarRefresh(true)
							setRefresh(true);
						},
						className: isActive('/jobs/completed')
					},
					{
						label: 'Cancelled',
						icon: 'pi pi-times',
						command:  () => {
							navigate.push('/jobs/cancelled')
							props.onSidebarClose(true)
							props.onSidebarRefresh(true)
							setRefresh(true);
						},
						className: isActive('/jobs/cancelled')
					},
					{
						label: 'Pro-Formas',
						icon: 'pi pi-file',
						command: () => {
							navigate.push('/jobs/pro-forma')
							props.onSidebarClose(true)
							props.onSidebarRefresh(true)
							setRefresh(true);
						},
						className: isActive('/jobs/pro-forma')
					}
				]
			};
		} else {
			jobs = {
				label: 'Jobs',
				items: [
					{
						label: 'Sales Orders',
						icon: 'pi pi-shopping-cart',
						command:  () => {
							navigate.push('/jobs/sales-order')
							props.onSidebarClose(true)
							setRefresh(true);
						},
						className: isActive('/jobs/sales-order', '/job/', false)
					},
					{
						label: 'On Hold',
						icon: 'pi pi-ban',
						template: (item, options) => {
							return (
								<>
									<div className={options.className} target={item.target} onClick={options.onClick}>
										<span className={'pi pi-ban me-2'}></span>
										<span className={options.labelClassName}>{item.label}</span>
										{
											isLoaded && onHoldCount > 0 && (
												<Badge className="ms-2" value={onHoldCount} />
											)
										}
									</div>
								</>
							)
						},
						command:  () => {
							navigate.push('/jobs/on-hold')
							props.onSidebarClose(true)
							setRefresh(true);
						},
						className: isActive('/jobs/on-hold')
					},
					{
						label: 'Priority',
						icon: 'pi pi-exclamation-circle',
						template: (item, options) => {
							return (
								<>
									<div className={options.className} target={item.target} onClick={options.onClick}>
										<span className={'pi pi-exclamation-circle me-2'}></span>
										<span className={options.labelClassName}>{item.label}</span>
										{
											isLoaded && props.priority > 0 && (
												<Badge className="ms-2" value={props.priority} />
											)
										}
									</div>
								</>
							)
						},
						command:  () => {
							navigate.push('/jobs/priority')
							props.onSidebarClose(true)
							setRefresh(true);
						},
						className: isActive('/jobs/priority')
					},
					{
						label: 'In Production',
						icon: 'pi pi-wrench',
						template: (item, options) => {
							return (
								<>
									<div className={options.className} target={item.target} onClick={options.onClick}>
										<span className={'pi pi-wrench me-2'}></span>
										<span className={options.labelClassName}>{item.label}</span>
										{
											isLoaded && inProductionCount > 0 && (
												<Badge className="ms-2" value={inProductionCount} />
											)
										}
									</div>
								</>
							)
						},
						command:  () => {
							navigate.push('/jobs/in-production')
							props.onSidebarClose(true)
							setRefresh(true);
						},
						className: isActive('/jobs/in-production')
					},
					{
						label: 'Completed',
						icon: 'pi pi-truck',
						command:  () => {
							navigate.push('/jobs/completed')
							props.onSidebarClose(true)
							setRefresh(true);
						},
						className: isActive('/jobs/completed')
					},
					{
						label: 'Cancelled',
						icon: 'pi pi-times',
						command:  () => {
							navigate.push('/jobs/cancelled')
							props.onSidebarClose(true)
							setRefresh(true);
						},
						className: isActive('/jobs/cancelled')
					},
					{
						label: 'Pro-Formas',
						icon: 'pi pi-file',
						command: () => {
							navigate.push('/jobs/pro-forma')
							props.onSidebarClose(true)
							setRefresh(true);
						},
						className: isActive('/jobs/pro-forma')
					}
				]
			};
		}

		let companyItems = [];

		if(props.companyMenuItems !== undefined) {
			props.companyMenuItems.forEach(res => {
				companyItems.push({
					label: res.name,
					icon: 'pi pi-building',
					command:  () => {
						navigate.push('/companies/' + res.id)
						props.onSidebarClose(true)
						setRefresh(true);
					},
					className: isActive('/companies/' + res.id)
				});
			});
		}

		let customerItems = [];

		if(props.customerMenuItems !== undefined) {
			props.customerMenuItems.forEach(res => {
				customerItems.push({
					label: res.name,
					icon: 'pi pi-globe',
					command:  () => {
						navigate.push('/customers/' + res.id)
						props.onSidebarClose(true)
						setRefresh(true);
					},
					className: isActive('/customers/' + res.id)
				});
			});
		}

		let customers = {
			label: 'Customers',
			items: customerItems
		}

		let companies = {
			label: 'Companies',
			items: companyItems
		}

		let products = {
			label: 'Products',
			items: [
				{
					label: 'All Products',
					icon: 'pi pi-box',
					command: () => {
						navigate.push('/products')
						props.onSidebarClose(true)
						setRefresh(true);
					},
					className: isActive('/products', '/product/', false)
				},
				{
					label: 'Battery Packs',
					icon: 'pi pi-bolt',
					command: () => {
						navigate.push('/products/battery-packs')
						props.onSidebarClose(true)
						setRefresh(true);
					},
					className: isActive('/products/battery-packs')
				},
				{
					label: 'Cells',
					icon: 'pi pi-database',
					command: () => {
						navigate.push('/products/cells')
						props.onSidebarClose(true)
						setRefresh(true);
					},
					className: isActive('/products/cells')
				},
				{
					label: 'Components', 
					icon: 'pi pi-cog',
					command: () => {
						navigate.push('/products/components')
						props.onSidebarClose(true)
						setRefresh(true);
					},
					className: isActive('/products/components')
				},
				{
					label: 'Chargers',
					icon: 'pi pi-times-circle',
					command: () => {
						navigate.push('/products/chargers')
						props.onSidebarClose(true)
						setRefresh(true);
					},
					className: isActive('/products/chargers')
				},
				{
					label: 'Accessories',
					icon: 'pi pi-th-large',
					command: () => {
						navigate.push('/products/accessories')
						props.onSidebarClose(true)
						setRefresh(true);
					},
					className: isActive('/products/accessories')
				}
			]
		}

		let reports = {
			label: 'Reports',
			items: [
				{
					label: 'Order',
					icon: 'pi pi-chart-line',
					command:  () => {
						navigate.push('/reports/orders')
						props.onSidebarClose(true)
						setRefresh(true);
					},
					className: isActive('/reports/orders')
				},
				{
					label: 'Product',
					icon: 'pi pi-chart-bar',
					command:  () => {
						navigate.push('/reports/products')
						props.onSidebarClose(true)
					},
					className: isActive('/reports/products')
				},
				{
					label: 'Employee',
					icon: 'pi pi-chart-pie',
					command:  () => {
						navigate.push('/reports/employees')
						props.onSidebarClose(true)
					},
					className: isActive('/reports/employees')
				}
			]
		}

		let travellers = {
			label: 'Travellers',
			items: [
				{
					label: 'Prepared',
					icon: 'pi pi-tablet',
					command:  () => {
						navigate.push('/travellers/prepared')
						props.onSidebarClose(true)
					},
					className: isActive('/travellers/prepared')
				},
				{
					label: 'In Progress',
					icon: 'pi pi-wrench',
					command:  () => {
						navigate.push('/travellers/in-progress')
						props.onSidebarClose(true)
					},
					className: isActive('/travellers/in-progress')
				},
				{
					label: 'On Hold',
					icon: 'pi pi-ban',
					command:  () => {
						navigate.push('/travellers/on-hold')
						props.onSidebarClose(true)
					},
					className: isActive('/travellers/on-hold')
				},
				{
					label: 'Cancelled',
					icon: 'pi pi-times',
					command:  () => {
						navigate.push('/travellers/cancelled')
						props.onSidebarClose(true)
					},
					className: isActive('/travellers/cancelled')
				},
				{
					label: 'Completed',
					icon: 'pi pi-truck',
					command:  () => {
						navigate.push('/travellers/completed')
						props.onSidebarClose(true)
					},
					className: isActive('/travellers/completed')
				},
				{
					label: 'Scraps',
					icon: 'pi pi-trash',
					command:  () => {
						navigate.push('/scraps')
						props.onSidebarClose(true)
					},
					className: isActive('/scraps', '/scrap/', false)
				},
				{
					label: 'Suggestions',
					icon: 'pi pi-comments',
					command:  () => {
						navigate.push('/suggestions')
						props.onSidebarClose(true)
					},
					className: isActive('/suggestions', '/suggestion/', false)
				}
			]
		}
	
		if(props.capabilities.edit_processes == true) {
			products.items.push({
				label: 'Processes',
				icon: 'pi pi-stopwatch',
				command:  () => {
					navigate.push('/processes')
					props.onSidebarClose(true)
				},
				className: isActive('/processes')
			});
		}
	
		let employees = {
			label: 'Employees',
			items: [
				{
					label: 'Account Managers',
					icon: 'pi pi-user',
					command:  () => {
						navigate.push('/employees/account-manager')
						props.onSidebarClose(true)
					},
					className: isActive('/employees/account-manager')
				},
				{
					label: 'Production Managers',
					icon: 'pi pi-user',
					command:  () => {
						navigate.push('/employees/production-manager')
						props.onSidebarClose(true)
					},
					className: isActive('/employees/production-manager')
				},
				{
					label: 'Production Operators',
					icon: 'pi pi-user',
					command:  () => {
						navigate.push('/employees/production-operator')
						props.onSidebarClose(true)
					},
					className: isActive('/employees/production-operator')
				}
			]
		}

		let displayName = '';

		if(props.user.display_name != undefined) {
			if(props.user.display_name.slice(-1) === 's') {
				displayName = props.user.display_name + '\'';
			} else {
				displayName = props.user.display_name + '\'s';
			}
		}
	
		let account = {
			label: displayName + ' Account',
			items: [
				{
					label: 'Sign Out',
					icon: 'pi pi-sign-out',
					command:  () => {
						localStorage.removeItem("userCapabilities");
						localStorage.removeItem("userToken");
	
						setNavigation([])
						props.onLogout(true)
					}
				},
			],
		};

	let items = [];

	// If user can access jobs
	if(props.capabilities.edit_jobs == true || props.capabilities.view_jobs == true) {
		items.push(jobs);
		items.push(companies);

		if(customerItems.length > 0) {
			items.push(customers);
		}
	}

	if(props.capabilities.edit_travellers == true || props.capabilities.view_travellers == true) {
		// If user can access travellers
		items.push(travellers);
	}

	if(props.capabilities.edit_products == true) {
		items.push(products);
	}

	// If user can access reports
	if(props.capabilities.view_reporting == true) {
		//items.push(reports);
	}

	if(props.capabilities.edit_employees == true) {
		// If user can access employees
		items.push(employees);
	}

	items.push(account);

	axios.post(getUrl() + '/wp-json/jwt-auth/v1/token/validate', {}, {headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`}}).then(
		res => {
			if(res.data.code !== 'jwt_auth_valid_token') {
				localStorage.removeItem("userCapabilities");
				localStorage.removeItem("userToken");

				setNavigation([])
				props.onLogout(true)
			}
		}
	).catch(err => console.log(err));

	setNavigation(items)
  }, [location.pathname, isLoaded, props.statuses, props.companyMenuItems, props.capabilities, localStorage.getItem('userCapabilities')]);

	let logoUrl = '';

	if(props.capabilities.edit_jobs == true) {
		logoUrl = '/jobs/sales-order';
	} else if(props.capabilities.edit_products == true) {
		logoUrl = '/products';
	} else if(props.capabilities.edit_travellers == true) {
		logoUrl = '/travellers';
	}

	return (
		<>
			<div className="flex justify-content-center">
				<ScrollPanel style={{ width: '100%', height: '100vh' }} >
					<a href={logoUrl} className="logo-link"><img src="/logo.webp" alt="Company Logo" width="200" height="100%" style={{padding: '1.6rem 1.25rem' }} /></a>
					<Menu model={navigation} className="custombar1" />
				</ScrollPanel>
			</div> 
		</>
	)
} 
