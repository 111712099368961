import React, { Component } from 'react';
import axios from 'axios';
import { FilterMatchMode } from 'primereact/api';

import { Link } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import { Toast } from 'primereact/toast';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getUrl, formatDate } from '../planner/planner';

export class Suggestions extends Component { 
	constructor(props) {
		super(props);

		this.state = {
			suggestions: [],
			expandedRows: null,
			tableCount: null,
			filters: {
				status: { value: null, matchMode: FilterMatchMode.CONTAINS },
				global: { value: null, matchMode: FilterMatchMode.CONTAINS },
			},
			selectedStatusFilter: null,
			globalFilterValue: '',
			columns: [
				{ field: 'date', header: 'Date', sortable: true },
				{ field: 'traveller.number', header: 'Traveller #' },
				{ field: 'status', header: 'Status', sortable: true },
				{ field: 'operator', header: 'Operator', sortable: true },
				{ field: 'suggestion', header: 'Suggestion', sortable: true },
			],
			visibleColumns: [],
			operators: [],
			isLoaded: false,
			binDialog: false,
			errorToast: React.createRef(),
			toast: React.createRef()
		}

		this.getEditButton = this.getEditButton.bind(this);
	}

	/**
	 * Update the global filtering to filter table contents
	 * 
	 * @param {input} e Information related to the current input
	 */
	onGlobalFilterChange(e) {
		const value = e.target.value;

		let _filters = { ...this.state.filters };

		_filters['global'].value = value;

		this.setState({filters: _filters, globalFilterValue: value })
	}

	/**
	 * Update the status filtering based on what the user has selected
	 * 
	 * @param {input} e Information related to the current input
	 */
	onStatusFilterChange(e) {
		let _filters = { ...this.state.filters };

		_filters['status'].value = e.target.value;

		this.setState({filters: _filters, selectedStatusFilter: e.target.value })
	}

	/**
	 * Update the status filtering based on what the user has selected
	 * 
	 * @param {input} e Information related to the current input
	 */
	onOperatorFilterChange(e) {
		let _filters = { ...this.state.filters };

		_filters['operator'].value = e.target.value;

		this.setState({filters: _filters, selectedOperatorFilter: e.target.value })
	}

	/**
	 * Gets the products for the DataTable
	 */
	updateSuggestions() {
		axios.get(getUrl() + '/wp-json/planner/v1/travellers/suggestions', { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
		.then(res => {
			this.setState({
				suggestions: res.data,
				isLoaded: true
			})
		}).catch(err => {
			this.state.errorToast.current.show({ severity: 'error', summary: 'Oops 🤦‍♂️', sticky: true, detail: 'An error has occurred, please contact IT support.' });
			console.log(err)
		});	
	}

	/**
	 * When the component isLoaded c = this.state;reated get the data from the REST API and set the columns based on the current users preferences
	 */
	componentDidMount() {
		document.title = 'Suggestions - Planner';

		axios.get(getUrl() + '/wp-json/planner/v1/users/production_operator', { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
		.then(res => this.setState({
			operators: res.data
		})).catch(err => console.log(err));	

		// If the user has selected custom columns, pre load these or use the default.
		if(localStorage.getItem('visibleColumnsSuggestions')) {
			this.setState({
				visibleColumns: JSON.parse(localStorage.getItem('visibleColumnsSuggestions'))
			});
		} else {
			this.setState({
				visibleColumns: this.state.columns
			});
		}

		this.updateSuggestions();
	}

	/**
	 * Get the edit button for the suggestions
	 * 
	 * @param {Object} rowData Information for the current row
	 * @returns {html} Get the edit button for the suggestions
	 */
	getEditButton(rowData) {
		const url = "suggestion/" + rowData.id;
		let icon = <i className="pi pi-lock me-1" style={{ fontSize: '0.8rem' }}></i>;
		let text = 'View';

		// Check if the current row is locked, change the icon and text based on if it is or not
		if((rowData.isLocked === false || rowData.isLocked.ID === this.props.currentLoggedInUser.id) && this.props.capabilities.edit_travellers === true) {
			icon = <i className="pi pi-pencil me-1" style={{ fontSize: '0.8rem' }}></i>;
			text = 'Edit';
		}

		return (<Link to={url} className="btn btn-primary">{icon} {text}</Link>);
	}

	/**
	 * Get the traveller number and link the id to the traveller page
	 * 
	 * @param {Object} rowData Information for the current row
	 * @returns {html} Get the link for the traveller and output the id
	 */
	getTravellerNumber(rowData) {
		return <Link to={"/traveller/" + rowData.traveller.id}>{rowData.traveller.number}</Link>
	}

	/**
	 * Get the badge for the status
	 * 
	 * @param {Object} rowData Information for the current row
	 * @returns {html} Get the status with the correct colouring in a badge
	 */
	getStatus(rowData) {
		let statusNoPrefix = rowData.status.replace('Planner-','');
		let className = '';

		const status = statusNoPrefix.charAt(0).toUpperCase() + statusNoPrefix.slice(1);

		if(status === 'Resolved') {
			className += 'bg-success';
		} else if(status === 'Dismissed') {
			className += 'bg-danger';
		} else {
			className += 'bg-dark';
		}

		return <span className={className + ' badge text-white'}>{status}</span>
	}

	/**
	 * Get the truncated suggestion
	 * 
	 * @param {Object} rowData Information for the current row
	 * @returns {string} Get the truncated suggestion
	 */
	getSuggestion(rowData) {
		let suffix = '';

		if(rowData.suggestion.length > 50) {
			suffix = ' [...]';
		}

		return rowData.suggestion.substring(0, 50) + suffix;
	}

	/**
	 * Clears the global and status filters
	 */
	clearFilters() {
		let _filters = { ...this.state.filters };

		_filters['status'].value = null;

		this.setState({
			selectedStatusFilter: null,
			filters: _filters
		});
	}

	/**
	 * Clear the global search filtering
	 */
	clearSearch() {
		let _filters = { ...this.state.filters };

		_filters['global'].value = null;

		this.setState({
			globalFilterValue: '',
			filters: _filters
		})
	}

	/**
	 * Get the date the suggestion was created
	 * 
	 * @param {Object} rowData Information for the current row
	 * @returns {string} The formatted string of the formatted date and time
	 */
	getDate(rowData) {
		return formatDate(rowData.date, true);
	}

	/**
	 * Sets the state for the selected columns and saves in local storage so it's remembered for future sessions.
	 * 
	 * @param {Event} event The selected column
	 */
	onColumnToggle(event) {
		let selectedColumns = event.value;
		let orderedSelectedColumns = this.state.columns.filter((col) => selectedColumns.some((sCol) => sCol.field === col.field));

		this.setState({visibleColumns: orderedSelectedColumns})
		localStorage.setItem('visibleColumnsSuggestions', JSON.stringify(orderedSelectedColumns));
	}
	/**
	 * Get the HTML to create the filtering options on the table header
	 * 
	 * @returns The HTML for the header for the table
	 */
	renderHeader() {
		return (
			<div className="container">
				<div className="d-flex justify-content-end">
					{
						this.state.selectedStatusFilter && !this.props.singleStatus && (
							<Button type="button" label="Reset" onClick={this.clearFilters.bind(this)} className="me-2" />
						)
					}
					<div>
						<Dropdown
							options={[
								'Active',
								'Resolved',
								'Dismissed'
							]}
							value={this.state.selectedStatusFilter}
							placeholder="Status Filter" className="me-2" onChange={this.onStatusFilterChange.bind(this)} />	
					</div>
					{
						<MultiSelect placeholder="Select Job Columns" value={this.state.visibleColumns} options={this.state.columns} onChange={this.onColumnToggle.bind(this)} optionLabel="header" className="me-2 columnToggling" display="chip" />
					}
					<div>
						<span className="p-input-icon-left p-input-icon-right">
							<i className="pi pi-search" />
							<InputText placeholder="Search..." value={this.state.globalFilterValue} onChange={this.onGlobalFilterChange.bind(this)} />
							{
								this.state.globalFilterValue != null && this.state.globalFilterValue !== '' && (
									<i className="pi pi-times" onClick={this.clearSearch.bind(this)} />
								)
							}
						</span>
					</div>
				</div>
			</div>
		)
	}

	getSuggestionsTitle() {
		if(this.props.capabilities.edit_travellers === true || this.props.capabilities.view_travellers === true) {
			return this.props.title;
		} else {
			return 'Access Denied';
		}
	}

	getOperator(rowData) {
		return <Link to={'/employee/' + rowData.operator_id}>{rowData.operator}</Link>
	}

	render() {
		const { errorToast, isLoaded, suggestions, expandedRows, filters, tableCount, visibleColumns } = this.state;

		let suggestionCount = 0;

		if(tableCount === null) {
			suggestionCount = this.state.suggestions.length;
		} else {
			suggestionCount = this.state.tableCount;
		}

		let loadingClass = 'isLoading';

		if(isLoaded) {
			loadingClass = 'notLoading';
		}

		return (
			<>
				<Toast ref={errorToast} position="center" />
				<div className="edit-order-bar position-sticky top-0 py-3 mb-5" >
					<div className="mx-5 px-0 d-flex justify-content-between align-items-center w-100" style={{maxWidth: "100%"}}>
						<div className="d-flex">
							<div className="d-flex align-items-center">
								<Button className="bg-primary p-2 me-3 d-flex align-items-center rounded d-xxl-none" onClick={e => {this.props.updateSidebarOpened(true);}}>
									<i className="pi pi-bars text-white" style={{ fontSize: '1.25rem' }}></i>
								</Button>
							</div>
							<div> 
								<h1 className="mb-0 h3">{this.getSuggestionsTitle()}</h1>
								{
									(this.props.capabilities.edit_travellers === true || this.props.capabilities.view_travellers === true) && (<>
										<strong>Total Suggestions:</strong> {suggestionCount} of {this.state.suggestions.length}
									</>)
								}
							</div>
						</div>
					</div>
				</div>
				{
					(this.props.capabilities.edit_travellers || this.props.capabilities.view_travellers) && ( // https://stackoverflow.com/questions/70682832/
						<div className="card mx-5 mb-5">
							{
								!isLoaded && (
									<div className={"mx-5 mb-3"} style={{ position: 'absolute', top: '3rem', zIndex: '99999', left: '45%' }}>
										<i className="pi pi-spin pi-spinner" style={{ fontSize: '6rem' }}></i>
									</div>
								)
							}
							<DataTable className={loadingClass} paginator rows={10} rowsPerPageOptions={[10, 25, 50]} globalFilterFields={['operator']} onValueChange={filteredData => this.setState({tableCount: filteredData.length})} header={this.renderHeader.bind(this)} filters={filters} expandedRows={expandedRows} onRowToggle={(e) => this.setState({expandedRows: e.data})} rowExpansionTemplate={this.rowExpansionTemplate} dataKey="id" size="small" sortOrder={1} sortField="name" value={suggestions} tableStyle={{ minWidth: '50rem' }}>
								<Column key="number" field="number" header="Suggestion #" sortable={true} />
								{visibleColumns.map((col) => {
									// Absolute shit code but LocalStorage won't store the body functions. See if there's a better way to do this.
									if(col.field === 'date') {
										return (<Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} body={this.getDate} />)
									} else if(col.field === 'traveller.number') {
										return (<Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} body={this.getTravellerNumber} />)
									} else if(col.field === 'status') {
										return (<Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} body={this.getStatus} />)
									} else if(col.field === 'operator') {
										return (<Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} body={this.getOperator} />)
									} else if(col.field === 'suggestion') {
										return (<Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} body={this.getSuggestion} />)
									} else {
										return (<Column key={col.field} field={col.field} header={col.header} sortable={col.sortable} />)
									}
								})}

								<Column key="edit" field="edit" header="" body={this.getEditButton} style={{ minWidth: '6rem' }} />
							</DataTable>
						</div>
					)
				}
			</>
		);
	}
}
export default Suggestions