import React from "react";
import EditProductForm from './edit-product-form';
import { useLocation } from 'react-router-dom';

export default function OrderWrapper(props) {
	const paths = useLocation().pathname.split('/');

	function sidebarOpened() {
		props.updateSidebarOpened(true);
	}

	return (
		<EditProductForm currentLoggedInUser={props.currentLoggedInUser} id={paths[paths.length - 1]} capabilities={props.capabilities} availableProcesses={props.availableProcesses} availableManufacturers={props.availableManufacturers} onSidebarOpen={sidebarOpened} />
	)
}