import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import axios from 'axios';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';

import Header from './components/sidebar/sidebar';
import { getUrl } from './components/planner/planner';

// Process Component
import Processes from './components/processes/processes-container';

// Product Components
import Products from './components/products/product-container';
import EditProductContainer from './components/products/edit-product-container';

// Scrap Components
import Scraps from './components/scraps/scrap-container';
import EditScrapContainer from './components/scraps/edit-scrap-container';

// Order Components
import OrdersContainer from './components/orders/orders-container';
import CustomerOrdersContainerWrapper from './components/orders/customer-orders-container-wrapper';
import CompanyOrdersContainerWrapper from './components/orders/company-orders-container-wrapper';
import EditOrderContainer from './components/orders/edit-order-container';

// Report Components
import OrdersDashboardContainer from './components/reports/orders-dashboard-container';
import ProductsDashboardContainer from './components/reports/products-dashboard-container';
import EmployeesDashboardContainer from './components/reports/employees-dashboard-container';

// Suggestion Components
import Suggestions from './components/suggestions/suggestions-container';
import EditSuggestionContainer from './components/suggestions/edit-suggestion-container';

// Employees Components
import Employees from './components/employees/employees-container';
import EmployeeContainer from './components/employees/view-employees-container';

// Traveller Components
import Travellers from './components/travellers/travellers-container';
import EditTravellerContainer from './components/travellers/edit-traveller-container';

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

var parse = require('html-react-parser');

function App() {
	const [availableStatuses, setAvailableStatuses] = useState([]);
	const [availableProcesses, setAvailableProcesses] = useState([]);
	const [availableManufacturers, setAvailableManufacturers] = useState([]);
	const [statuses, setStatuses] = useState(null);
	const [priority, setPriority] = useState(0);
	const [token, setToken] = useState(null);
	const [showLoginBox, setShowLoginBox] = useState(false);
	const [userPermissions, setUserPermissions] = useState([]);
	const [companyMenuItems, setCompanyMenuItems] = useState([]); 
	const [customerMenuItems, setCustomerMenuItems] = useState([]);
	const [travellerStatusesCount, setTravellerStatusesCount] = useState([]);
	const [currentLoggedInUser, setCurrentLoggedInUser] = useState([]);
	const [sidebarOpen, setSidebarOpen] = useState(false);
	const [loginUsername, setLoginUsername] = useState('');
	const [loginPassword, setLoginPassword] = useState('');
	const [usernameFailed, setUsernameFailed] = useState(false);
	const [batteryPackId, setBatteryPackId] = useState('');
	const [componentId, setComponentId] = useState('');
	const [cellId, setCellId] = useState('');
	const [chargerId, setChargerId] = useState('');
	const [accessoryId, setAccessoryId] = useState('');
	const [lastSyncedOrders, setLastSyncedOrders] = useState('');
	const [loginErrorMessage, setLoginErrorMessage] = useState('');
	const [sidebarRefresh, setSidebarRefresh] = useState(false);

	useEffect(() => {
		if(sidebarRefresh != true) {
			if(localStorage.getItem('userToken') || token !== null) {
				axios.get(getUrl() + '/wp-json/planner/v1/sidebar', { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
				.then(res => {
					setBatteryPackId(res.data.product_categories_menu_items.product_categories.battery_pack)
					setComponentId(res.data.product_categories_menu_items.product_categories.component)
					setCellId(res.data.product_categories_menu_items.product_categories.cell)
					setChargerId(res.data.product_categories_menu_items.product_categories.charger)
					setAccessoryId(res.data.product_categories_menu_items.product_categories.accessory)
					setCompanyMenuItems(res.data.companies_menu_items)
					setLastSyncedOrders(res.data.last_synced_orders) 
				})
				.catch(err => console.log(err));

				axios.get(getUrl() + '/wp-json/planner/v1/settings', { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
				.then(res => {
					setAvailableStatuses(res.data.statuses)
					setAvailableManufacturers(res.data.manufacturers)
				})
				.catch(err => console.log(err));

				if(localStorage.getItem('userCapabilities')) {
					setUserPermissions(JSON.parse(localStorage.getItem('userCapabilities')));
					setCurrentLoggedInUser(JSON.parse(localStorage.getItem('currentLoggedInUser')));

					if(localStorage.getItem('customerMenuItems')) {
						setCustomerMenuItems(JSON.parse(localStorage.getItem('customerMenuItems')));
					}
				} else {
					axios.get(getUrl() + '/wp-json/planner/v1/sidebar', { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
					.then(res => {
						setUserPermissions(res.data.current_planner_user.capabilities)

						if(res.data.current_planner_user.customers_menu_items) {
							setCustomerMenuItems(res.data.current_planner_user.customers_menu_items);
	
							localStorage.setItem('customerMenuItems', JSON.stringify(res.data.current_planner_user.customers_menu_items));
						}
	
						setCurrentLoggedInUser(res.data.current_planner_user)
						localStorage.setItem('userCapabilities', JSON.stringify(res.data.current_planner_user.capabilities));
						localStorage.setItem('currentLoggedInUser', JSON.stringify(res.data.current_planner_user));
					})
					.catch(err => console.log(err));
				}
	
				axios.get(getUrl() + '/wp-json/planner/v1/travellers/processes', { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
				.then(res => {
					setAvailableProcesses(res.data)
				})
				.catch(err => console.log(err));
			}
	
			getStatuses();
	
			if(!localStorage.getItem('userCapabilities') && !localStorage.getItem('userToken')) {
				setShowLoginBox(true);
			}	
		} else {
			getStatuses();

			setSidebarRefresh(false);
		}
	}, [token, localStorage.getItem('userToken')]);

	function closeSidebar() {
		setSidebarOpen(false);

		document.body.classList.add('sidebar-closed');
		document.body.classList.remove('sidebar-open');
	}

	function updateSidebar() {
		setSidebarRefresh(true);
	}

	async function getStatuses() {
		await axios.get(getUrl() + '/wp-json/planner/v1/settings', { headers: {"Authorization" : `Bearer ${localStorage.getItem('userToken')}`} })
		.then(res => {
			setStatuses(res.data.status_count);
			setPriority(res.data.status_count.priority_orders_count)
		})
		.catch(err => console.log(err));
	}

	function hasUpdatedAppStatusCount(oldStatus, newStatus) {
		//let oldStatuses = statuses;

		/*for (const [key, value] of Object.entries(statuses.order_statuses_count)) {
			if(key === oldStatus) {
				oldStatuses.order_statuses_count[key] = parseInt(value) - 1
			}

			if(key === newStatus) {
				oldStatuses.order_statuses_count[key] = parseInt(value) + 1
			}
		}

		setStatuses(oldStatuses);*/

		//forceUpdate();
	}

	function toggleSidebar() {
		setSidebarOpen(true);

		document.body.classList.add('sidebar-open');
		document.body.classList.remove('sidebar-closed');
	}

	function systemLogout() {
		setToken('');
	}

	async function systemLogin() {
		const [isLoggedIn] = await Promise.all([
			axios.post(getUrl() + '/wp-json/jwt-auth/v1/token', {
				"username" : loginUsername,
				"password" : loginPassword
			}).then(
				res => {
					setToken(res.data.token)
					localStorage.setItem('userToken', res.data.token);
					setUsernameFailed(false);
					setLoginErrorMessage('');
					setShowLoginBox(false);

					setLoginUsername('');
					setLoginPassword('');
				}
			).catch(err => {
				setLoginErrorMessage("<strong>Error: </strong>The username, email or password is incorrect.")

				if(err.response.data.message !== '') {
					setUsernameFailed(true);
				}
			})
		]);

		if(loginErrorMessage !== '') {
			setUsernameFailed(true);
		}

		if(isLoggedIn) {
			axios.get(getUrl() + '/wp-json/planner/v1/sidebar', { headers: {"Authorization" : `Bearer ${token}`} })
			.then(res => {
				setUserPermissions(res.data.current_planner_user.capabilities)
				localStorage.setItem('userCapabilities', JSON.stringify(res.data.current_planner_user.capabilities));
			})
			.catch(err => console.log(err));
		}
	}

	function redirection() {
		if(userPermissions.view_jobs || userPermissions.edit_jobs) {
			return 'jobs';
		} else if(userPermissions.edit_travellers) {
			return 'travellers';
		} else {
			return false;
		}
	}

	const handleSubmit = (event) => {
		event.preventDefault();
		systemLogin();
	}

	return (
		<>
			<Dialog header="Login" visible={showLoginBox} style={{ width: '50vw' }} draggable={false} closable={false}>
				<form onSubmit={handleSubmit}>
					{
						usernameFailed && loginErrorMessage !== '' && (
							<p className="bg-danger text-white p-2">{parse(loginErrorMessage)}</p>
						)
					}
					<InputText value={loginUsername} onChange={(e) => {setLoginUsername(e.target.value); setUsernameFailed(false)}} placeholder="Username" className="w-100 mb-4" />
					<Password value={loginPassword} onChange={(e) => {setLoginPassword(e.target.value); setUsernameFailed(false)}} placeholder="Password" className="w-100 mb-4 d-block" feedback={false} />
					<Button type="submit" label="Submit" />
				</form>
			</Dialog>
			{
				localStorage.getItem('userToken') && localStorage.getItem('userCapabilities') && (
					<Router>
					{
						sidebarOpen && (<Button className="overlay" onClick={(e) => closeSidebar()}></Button>)
					}
					<div className="d-flex h-100">
						<Header statuses={statuses} priority={priority} travellerStatusesCount={travellerStatusesCount} currentLoggedInUser={currentLoggedInUser} capabilities={userPermissions} customerMenuItems={customerMenuItems} companyMenuItems={companyMenuItems} sidebarOpen={sidebarOpen} updateSidebarClosed={closeSidebar} updateSidebarRefresh={updateSidebar} updateSystemLogout={systemLogout} />
						<div className="container-fluid my-0 mx-0 px-0">
							<Switch>
								<Route exact path="/">
									{
										redirection() === 'jobs' && (
											<Redirect to="/jobs/sales-order" />
										)
									}
									{
										redirection() === 'travellers' && (
											<Redirect to="/travellers/prepared" />
										)
									}
								</Route>
								<Route exact path="/reports">
									<OrdersDashboardContainer />
								</Route>
								<Route exact path="/logout">
								</Route>
								<Route exact path="/reports/orders">
									<OrdersDashboardContainer title="Report: Orders" />
								</Route>
								<Route exact path="/reports/products">
									{
										batteryPackId && componentId && cellId && (
											<ProductsDashboardContainer title="Report: Products" batteryPackId={batteryPackId} componentId={componentId} cellId={cellId} />
										)
									}
								</Route>
								<Route exact path="/reports/employees">
									<EmployeesDashboardContainer title="Report: Employees" />
								</Route>
								<Route exact path="/jobs/sales-order">
									<OrdersContainer currentLoggedInUser={currentLoggedInUser} pageTitle="Sales Orders" updateSidebarOpened={toggleSidebar} capabilities={userPermissions} title="Jobs: Sales Orders" availableStatuses={availableStatuses} status="sales-order" />
								</Route>
								<Route path="/customers">
									<CustomerOrdersContainerWrapper currentLoggedInUser={currentLoggedInUser} pageTitle="Technolog" updateSidebarOpened={toggleSidebar} capabilities={userPermissions} lastSyncedOrders={lastSyncedOrders} title="Jobs: Technolog" accountCode="T011" availableStatuses={availableStatuses} status="sales-orders" />
								</Route>
								<Route path="/jobs/completed">
									<OrdersContainer currentLoggedInUser={currentLoggedInUser} pageTitle="Completed Jobs" updateSidebarOpened={toggleSidebar} capabilities={userPermissions} title="Jobs: Completed" availableStatuses={availableStatuses} status="completed" singleStatus="true" />
								</Route>
								<Route exact path="/jobs/cancelled">
									<OrdersContainer currentLoggedInUser={currentLoggedInUser} pageTitle="Cancelled Jobs" updateSidebarOpened={toggleSidebar} capabilities={userPermissions} title="Jobs: Cancelled" availableStatuses={availableStatuses} status="cancelled" singleStatus="true" />
								</Route>
								<Route exact path="/jobs/shortage">
									<OrdersContainer currentLoggedInUser={currentLoggedInUser} pageTitle="Shortage Jobs" updateSidebarOpened={toggleSidebar} capabilities={userPermissions} title="Jobs: Shortage" availableStatuses={availableStatuses} status="shortage" singleStatus="true" />
								</Route>
								<Route exact path="/jobs/pro-forma">
									<OrdersContainer currentLoggedInUser={currentLoggedInUser} pageTitle="Pro-Formas" updateSidebarOpened={toggleSidebar} capabilities={userPermissions} title="Jobs: Pro-Formas" availableStatuses={availableStatuses} status="pro-forma" />
								</Route>
								<Route exact path="/jobs/on-hold">
									<OrdersContainer currentLoggedInUser={currentLoggedInUser} pageTitle="On Hold Jobs" updateSidebarOpened={toggleSidebar} capabilities={userPermissions} title="Jobs: On Hold" availableStatuses={availableStatuses} status="on-hold" singleStatus="true" />
								</Route>
								<Route exact path="/jobs/in-production">
									<OrdersContainer currentLoggedInUser={currentLoggedInUser} pageTitle="In Production Jobs" updateSidebarOpened={toggleSidebar} capabilities={userPermissions} title="Jobs: In Production" availableStatuses={availableStatuses} status="processing" singleStatus="true" />
								</Route>
								<Route exact path="/jobs/priority">
									<OrdersContainer currentLoggedInUser={currentLoggedInUser} pageTitle="Priority Jobs" updateSidebarOpened={toggleSidebar} capabilities={userPermissions} title="Jobs: Priority" availableStatuses={availableStatuses} status="priority" />
								</Route>
								<Route exact path="/jobs/companies">
									<OrdersContainer currentLoggedInUser={currentLoggedInUser} pageTitle="Companies" updateSidebarOpened={toggleSidebar} capabilities={userPermissions} title="Jobs: All Company" availableStatuses={availableStatuses} />
								</Route>
								<Route path="/companies">
									<CompanyOrdersContainerWrapper currentLoggedInUser={currentLoggedInUser} updateSidebarOpened={toggleSidebar} capabilities={userPermissions} lastSyncedOrders={lastSyncedOrders} title="Jobs: Cell Pack Solutions" availableStatuses={availableStatuses} status="sales-orders" />
								</Route>
								<Route path="/job"> 
									<EditOrderContainer currentLoggedInUser={currentLoggedInUser} capabilities={userPermissions} availableStatuses={availableStatuses} updateSidebarOpened={toggleSidebar} updateAppStatusCount={hasUpdatedAppStatusCount} />
								</Route>
								<Route path="/products" exact>
									<Products currentLoggedInUser={currentLoggedInUser} pageTitle="Products" updateSidebarOpened={toggleSidebar} capabilities={userPermissions} title="Products" showTime={true} />
								</Route>
								<Route path="/products/battery-packs">
									{
										batteryPackId !== '' && (
											<Products currentLoggedInUser={currentLoggedInUser} pageTitle="Battery Packs" updateSidebarOpened={toggleSidebar} capabilities={userPermissions} title="Products: Battery Packs" singleCategory={true} category={batteryPackId} />
										)
									}
								</Route>
								<Route path="/products/cells">
									{
										cellId !== '' && (
											<Products currentLoggedInUser={currentLoggedInUser} pageTitle="Cells" updateSidebarOpened={toggleSidebar} capabilities={userPermissions} title="Products: Cells" singleCategory={true} hideTime={true} category={cellId}  />
										)
									}
								</Route>
								<Route path="/products/components">
									{
										componentId !== '' && (
											<Products currentLoggedInUser={currentLoggedInUser} pageTitle="Components" updateSidebarOpened={toggleSidebar} capabilities={userPermissions} title="Products: Components" singleCategory={true} hideTime={true} category={componentId} />
										)
									}
								</Route>
								<Route path="/products/accessories">
									{
										accessoryId !== '' && (
											<Products currentLoggedInUser={currentLoggedInUser} pageTitle="Accessories" updateSidebarOpened={toggleSidebar} capabilities={userPermissions} title="Products: Accessories" singleCategory={true} hideTime={true} category={accessoryId} />
										)
									}
								</Route>
								<Route path="/products/chargers">
									{
										chargerId !== '' && (
											<Products currentLoggedInUser={currentLoggedInUser} pageTitle="Chargers" updateSidebarOpened={toggleSidebar} capabilities={userPermissions} title="Products: Chargers" singleCategory={true} hideTime={true} category={chargerId} />
										)
									}
								</Route>
								<Route path="/product">
									<EditProductContainer currentLoggedInUser={currentLoggedInUser} updateSidebarOpened={toggleSidebar} capabilities={userPermissions} availableProcesses={availableProcesses} availableManufacturers={availableManufacturers} />
								</Route>
								<Route exact path="/travellers">
									<Travellers currentLoggedInUser={currentLoggedInUser} pageTitle="Travellers" updateSidebarOpened={toggleSidebar} title="Travellers" capabilities={userPermissions} />
								</Route>
								<Route path="/travellers/prepared">
									<Travellers currentLoggedInUser={currentLoggedInUser} pageTitle="Prepared Travellers" updateSidebarOpened={toggleSidebar} title="Travellers: Prepared" capabilities={userPermissions} status="prepared" />
								</Route>
								<Route path="/travellers/in-progress">
									<Travellers currentLoggedInUser={currentLoggedInUser} pageTitle="In Progress Travellers" updateSidebarOpened={toggleSidebar} title="Travellers: In Progress" capabilities={userPermissions} status="in-progress" />
								</Route>
								<Route path="/travellers/on-hold">
									<Travellers currentLoggedInUser={currentLoggedInUser} pageTitle="On Hold Travellers" updateSidebarOpened={toggleSidebar} title="Travellers: On Hold" capabilities={userPermissions} status="on-hold" />
								</Route>
								<Route path="/travellers/cancelled">
									<Travellers currentLoggedInUser={currentLoggedInUser} pageTitle="Cancelled Travellers" updateSidebarOpened={toggleSidebar} title="Travellers: Cancelled" capabilities={userPermissions} status="cancelled" />
								</Route>
								<Route path="/travellers/completed">
									<Travellers currentLoggedInUser={currentLoggedInUser} pageTitle="Completed Travellers" updateSidebarOpened={toggleSidebar} title="Travellers: Completed" capabilities={userPermissions} status="completed" />
								</Route>
								<Route path="/processes">
									<Processes updateSidebarOpened={toggleSidebar} capabilities={userPermissions} title="Processes" />
								</Route> 
								<Route path="/scraps">
									<Scraps currentLoggedInUser={currentLoggedInUser} updateSidebarOpened={toggleSidebar} capabilities={userPermissions} title="Scraps" />
								</Route>
								<Route path="/scrap">
									<EditScrapContainer currentLoggedInUser={currentLoggedInUser} updateSidebarOpened={toggleSidebar} capabilities={userPermissions} title="Scraps" />
								</Route>
								<Route path="/suggestions">
									<Suggestions currentLoggedInUser={currentLoggedInUser} updateSidebarOpened={toggleSidebar} capabilities={userPermissions} title="Suggestions" />
								</Route>
								<Route path="/employees/account-manager">
									<Employees updateSidebarOpened={toggleSidebar} title="Employees : Account Manager" role="account_manager" />
								</Route>
								<Route path="/employees/production-manager"> 
									<Employees updateSidebarOpened={toggleSidebar} title="Employees : Production Manager" role="production_manager" />
								</Route>
								<Route path="/employees/production-operator">
									<Employees updateSidebarOpened={toggleSidebar} title="Employees : Production Operator" role="production_operator" />
								</Route>
								<Route path="/employees/">
									<Employees updateSidebarOpened={toggleSidebar} title="Employees" />
								</Route>
								<Route path="/employee">
									<EmployeeContainer capabilities={userPermissions} updateSidebarOpened={toggleSidebar} title="Employee" />
								</Route>
								<Route path="/suggestion">
									<EditSuggestionContainer currentLoggedInUser={currentLoggedInUser} updateSidebarOpened={toggleSidebar} capabilities={userPermissions} title="Suggestion" />
								</Route>
								<Route path="/traveller">
									<EditTravellerContainer currentLoggedInUser={currentLoggedInUser} updateSidebarOpened={toggleSidebar} capabilities={userPermissions} />
								</Route>
							</Switch>
						</div>
					</div>
				</Router>
				)
			}
		</>
 );
}
export default App;